<template>
  <div>
    <div class="container">
      <div class="text-center">
        <div class="center">
          <Avatar :avatarUrl="config.blogger_avatar_url" size="300" home="true" />
        </div>
        <!-- <h1>{{ config.blog_title }}</h1> -->
        <h3 style="color: #666666">{{ config.blogger_name }}</h3>
        <p v-if="config.blog_description.includes('.')">
          <span v-for="(item, index) in config.blog_description.split('.')" :key="index"><i
              style="font-size: large;font-weight: bold;">{{ item }} .</i><br></span>
        </p>
        <p v-else>
          <i style="font-size: large;">{{ config.blog_description }}</i>
        </p>
        <!-- <FollowMe :githubUrl="config.github_url" :giteeUrl="config.gitee_url" :tiktokUrl="config.tiktok_url"></FollowMe> -->

        <div class="center">
          <vs-button icon color="#000000" @click="goTo('tiktok')" class="mx-1">
            <i class='bx bxl-tiktok'></i>
          </vs-button>

          <vs-button icon color="facebook" class="mx-1">
            <i class='bx bxl-facebook-square'></i>
          </vs-button>

          <vs-button icon color="twitter" class="mx-1">
            <i class='bx bxl-twitter'></i>
          </vs-button>

          <vs-button icon color="youtube" class="mx-1">
            <i class='bx bxl-youtube'></i>
          </vs-button>

          <vs-button icon color="linkedin" class="mx-1">
            <i class='bx bxl-linkedin'></i>
          </vs-button>

          <vs-button icon color="whatsapp" class="mx-1">
            <i class='bx bxl-whatsapp'></i>
          </vs-button>

          <vs-button icon color="#000000" @click="goTo('github')" class="mx-1">
            <i class='bx bxl-github'></i>
          </vs-button>

          <vs-button icon color="#DB4437" @click="goTo('gitee')" class="mx-1">
            <i class='bx bxl-github'></i>
          </vs-button>

          <vs-button icon @click="goTo('chatGpt')" class="mx-1">
            <i class='bx bxs-message-square-dots'></i>
          </vs-button>

          <vs-button icon color="skype" class="mx-1">
            <i class='bx bxl-skype'></i>
          </vs-button>

          <vs-button icon color="slack" class="mx-1">
            <i class='bx bxl-slack-old'></i>
          </vs-button>

          <vs-button icon color="messenger" class="mx-1">
            <i class='bx bxl-messenger'></i>
          </vs-button>

          <vs-button icon color="tumblr" class="mx-1">
            <i class='bx bxl-tumblr'></i>
          </vs-button>

          <vs-button icon color="dribbble" class="mx-1">
            <i class='bx bxl-dribbble'></i>
          </vs-button>

          <vs-button icon color="google-plus" class="mx-1">
            <i class='bx bxl-google-plus'></i>
          </vs-button>
        </div>

      </div>
      <h2>Contributions</h2>
      <ContributionPC :contributionData="contributionData" v-if="deviceType === 'pc1'"></ContributionPC>
      <ContributionMobile :deviceType="deviceType" v-else></ContributionMobile>
      <h2 style="margin-top: 45px;">Recommended</h2>
      <hr />
      <div class="grid-3_xs-1_sm-2_md-2" v-if="posts.length">
        <div :key="index" v-for="(post, index) in posts" class="col">
          <PostCard :that="that" :post="post" class="center" />
        </div>
      </div>
      <Nothing v-else />

      <!-- 公告 -->
      <h2 style="cursor: pointer;" @click="noticeGridFlag = !noticeGridFlag">Notices</h2>
      <hr />
      <vs-alert closable v-model="noticeGridFlag" :color="'dark'">
        <vs-table class="noticeTable">
          <template #thead>
            <vs-tr>
              <vs-th>
                Name
              </vs-th>
              <vs-th>
                Email
              </vs-th>
              <vs-th>
                Id
              </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in $vs.getPage(users, page, max)" :data="tr">
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td>
                {{ tr.email }}
              </vs-td>
              <vs-td>
                {{ tr.id }}
              </vs-td>
            </vs-tr>
          </template>
          <template #footer>
            <vs-pagination v-model="page" :length="$vs.getLength(users, max)" />
          </template>
        </vs-table>
      </vs-alert>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import PostCard from '@/components/PostCard.vue'
import Nothing from '@/components/Nothing.vue'
import ContributionPC from '@/components/Contribution_PC.vue'
import ContributionMobile from '@/components/Contribution_Mobile.vue'
// import FollowMe from '@/components/FollowMe.vue'
import Cookie from '@/components/Cookie.vue'

export default {
  name: 'Home',
  components: {
    Avatar,
    PostCard,
    Nothing,
    ContributionPC,
    ContributionMobile
    // FollowMe
  },
  data () {
    return {
      deviceType: this.common.getDeviceType(),
      posts: this.getConfig('posts.json').posts,
      config: {},

      contributionData: [
        ['2016-01-01', 60], ['2016-01-02', 40], ['2016-01-03', 75], ['2016-01-04', 22], ['2016-01-05', 83], ['2016-01-06', 37], ['2016-01-07', 68], ['2016-01-08', 55], ['2016-01-09', 29], ['2016-01-10', 80], ['2016-01-11', 42], ['2016-01-12', 71], ['2016-01-13', 15], ['2016-01-14', 90], ['2016-01-15', 63], ['2016-01-16', 54], ['2016-01-17', 36], ['2016-01-18', 78], ['2016-01-19', 47], ['2016-01-20', 82], ['2016-01-21', 25], ['2016-01-22', 69], ['2016-01-23', 56], ['2016-01-24', 41], ['2016-01-25', 87], ['2016-01-26', 49], ['2016-01-27', 30], ['2016-01-28', 65], ['2016-01-29', 38], ['2016-01-30', 74], ['2016-01-31', 52], ['2016-02-01', 66], ['2016-02-02', 44], ['2016-02-03', 79], ['2016-02-04', 28], ['2016-02-05', 91], ['2016-02-06', 53], ['2016-02-07', 70], ['2016-02-08', 45], ['2016-02-09', 88], ['2016-02-10', 33], ['2016-02-11', 67], ['2016-02-12', 59], ['2016-02-13', 26], ['2016-02-14', 84], ['2016-02-15', 48], ['2016-02-16', 39], ['2016-02-17', 72], ['2016-02-18', 31], ['2016-02-19', 85], ['2016-02-20', 50], ['2016-02-21', 76], ['2016-02-22', 43], ['2016-02-23', 86], ['2016-02-24', 20], ['2016-02-25', 94], ['2016-02-26', 34], ['2016-02-27', 61], ['2016-02-28', 57], ['2016-03-01', 62], ['2016-03-02', 38], ['2016-03-03', 74], ['2016-03-04', 29], ['2016-03-05', 90], ['2016-03-06', 55], ['2016-03-07', 68], ['2016-03-08', 41], ['2016-03-09', 82], ['2016-03-10', 36], ['2016-03-11', 67], ['2016-03-12', 59], ['2016-03-13', 26], ['2016-03-14', 84], ['2016-03-15', 48], ['2016-03-16', 39], ['2016-03-17', 72], ['2016-03-18', 31], ['2016-03-19', 85], ['2016-03-20', 50], ['2016-03-21', 76], ['2016-03-22', 43], ['2016-03-23', 86], ['2016-03-24', 20], ['2016-03-25', 94], ['2016-03-26', 34], ['2016-03-27', 61], ['2016-03-28', 57], ['2016-03-29', 66], ['2016-03-30', 44], ['2016-03-31', 79],
        ['2016-11-20', 92], ['2016-11-21', 87], ['2016-11-22', 18], ['2016-11-23', 53], ['2016-11-24', 78], ['2016-11-25', 53], ['2016-11-26', 83], ['2016-11-27', 29], ['2016-11-28', 4], ['2016-11-29', 84], ['2016-11-30', 36], ['2016-12-01', 58], ['2016-12-02', 51], ['2016-12-03', 69], ['2016-12-04', 19], ['2016-12-05', 35], ['2016-12-06', 5], ['2016-12-07', 14], ['2016-12-08', 44], ['2016-12-09', 74], ['2016-12-10', 49], ['2016-12-11', 52], ['2016-12-12', 48], ['2016-12-13', 92], ['2016-12-14', 16], ['2016-12-15', 78], ['2016-12-16', 27], ['2016-12-17', 45], ['2016-12-18', 44], ['2016-12-19', 41], ['2016-12-20', 92], ['2016-12-21', 60], ['2016-12-22', 73], ['2016-12-23', 75], ['2016-12-24', 25], ['2016-12-25', 7], ['2016-12-26', 98], ['2016-12-27', 1], ['2016-12-28', 63], ['2016-12-29', 93], ['2016-12-30', 77], ['2016-12-31', 46]
      ],

      that: this,

      noticeGridFlag: true,
      page: 1,
      max: 5,
      users: [
        {
          id: 1,
          name: 'Leanne Graham',
          username: 'Bret',
          email: 'Sincere@april.biz',
          website: 'hildegard.org'
        },
        {
          id: 2,
          name: 'Ervin Howell',
          username: 'Antonette',
          email: 'Shanna@melissa.tv',
          website: 'anastasia.net'
        },
        {
          id: 3,
          name: 'Clementine Bauch',
          username: 'Samantha',
          email: 'Nathan@yesenia.net',
          website: 'ramiro.info'
        },
        {
          id: 4,
          name: 'Patricia Lebsack',
          username: 'Karianne',
          email: 'Julianne.OConner@kory.org',
          website: 'kale.biz'
        },
        {
          id: 5,
          name: 'Chelsey Dietrich',
          username: 'Kamren',
          email: 'Lucio_Hettinger@annie.ca',
          website: 'demarco.info'
        },
        {
          id: 6,
          name: 'Mrs. Dennis Schulist',
          username: 'Leopoldo_Corkery',
          email: 'Karley_Dach@jasper.info',
          website: 'ola.org'
        },
        {
          id: 7,
          name: 'Kurtis Weissnat',
          username: 'Elwyn.Skiles',
          email: 'Telly.Hoeger@billy.biz',
          website: 'elvis.io'
        },
        {
          id: 8,
          name: 'Nicholas Runolfsdottir V',
          username: 'Maxime_Nienow',
          email: 'Sherwood@rosamond.me',
          website: 'jacynthe.com'
        },
        {
          id: 9,
          name: 'Glenna Reichert',
          username: 'Delphine',
          email: 'Chaim_McDermott@dana.io',
          website: 'conrad.com'
        },
        {
          id: 10,
          name: 'Clementina DuBuque',
          username: 'Moriah.Stanton',
          email: 'Rey.Padberg@karina.biz',
          website: 'ambrose.net'
        }
      ]
    }
  },
  methods: {
    goTo (name) {
      if (name === 'tiktok') {
        if (this.deviceType === 'pc') {
          this.$router.push('/tiktok')
        } else {
          window.location.href = 'snssdk1128://user/profile/4230552155325472'
        }
      } else if (name === 'github') {
        this.$router.push('/github')
      } else if (name === 'gitee') {
        this.$router.push('/gitee')
      } else if (name === 'chatGpt') {
        this.$router.push('/chatGpt')
      }
    }
  },
  mounted () {
    this.changeTitle('Home')
  },
  created () {
    this.request.GET('/config/get')
      .then(res => {
        this.config = res.data[0]

        this.common.eventBus().$emit('showFooter')
      })

    this.$vs.notification({
      duration: '3000',
      position: 'top-right',
      content: Cookie
    })
  }
}
</script>
<style scoped>
.vs-button {
  margin: 2.5px !important;
}
</style>
