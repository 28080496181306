<template>
  <div class="container-card">
    <ul class="graph">
      <div v-for="(item, index) in infos" :key="index" class="item">
        <vs-tooltip bottom>
          <li
            :data-level="item.level"
            class="li-day"
          ></li>
          <template #tooltip>
            {{ item.year + '-' + item.month + '-' + item.date }}
        </template>
        </vs-tooltip>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['deviceType'],
  data () {
    return {
      infos: [],
      current: {
        year: '',
        month: '',
        date: ''
      }
    }
  },
  methods: {
    handleClick: function (item) {
      alert(item.year + '-' + item.month + '-' + item.date)
    }
  },
  mounted () {
    // 列数
    const graphWidth = document.getElementsByClassName('graph')[0].offsetWidth
    const colCount = Math.ceil(graphWidth / 22) > 52 ? 52 : Math.ceil(graphWidth / 22)

    const d = new Date()
    const day = d.getDay()
    const today = d.getDate()

    this.current.year = d.getFullYear()
    this.current.month = d.getMonth()
    this.current.date = d.getDate()

    let info = {}

    for (let i = 0; i < colCount * 7; i++) {
      d.setFullYear(this.current.year)
      d.setMonth(this.current.month)
      d.setDate(this.current.date)

      d.setDate(today - 77 - day + i)

      const level = Math.floor(Math.random() * 4)

      if (
        d.getMonth() === this.current.month &&
        d.getDate() === this.current.date
      ) {
        info = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          date: d.getDate(),
          level: level,
          isToday: true
        }
        this.infos.push(info)
      } else {
        info = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          date: d.getDate(),
          level: level,
          isToday: false
        }
        this.infos.push(info)
      }
    }
  }
}
</script>

<style scoped>

.container-card {
  margin: 20px auto;
  padding: 5px 5px 0px 5px;
  border: 1px solid #ebeef5;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 5px #cfcfcf;
  max-width: 1200px;
}

.graph {
  display: grid;
  grid-template-columns: repeat(12, 21px);
  grid-template-rows: repeat(7, 21px);
  padding-inline-start: 0px;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
}

.months {
  display: grid;
  grid-template-columns: repeat(12, 21px);
  grid-template-rows: 21px;
  font-size: 8px;
  color: #aaa;
  padding-inline-start: 0px;
  margin: 5px 20px 5px 20px;
}

.li-month {
  display: inline-block;
}

.li-day {
  background-color: #eaeaea;
  list-style: none;
  border-radius: 3px;
  margin: 1px;
  width: 20px;
  height: 20px;
}

/* .li-day:hover {
  box-shadow: 0px 0px 5px rgb(57, 120, 255);
} */

/* .li-day[isToday="true"] {
  background-color: rgb(234, 234, 234);
  box-shadow: 0px 0px 5px rgb(57, 120, 255);
  list-style: none;
  border-radius: 3px;
  border: 1px solid #000;
} */

.graph div li[data-level="1"] {
  background-color: #d9ecff;
}

.graph div li[data-level="2"] {
  background-color: #8cc5ff;
}

.graph div li[data-level="3"] {
  background-color: #409eff;
}

</style>
