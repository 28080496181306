<template>
    <div>
        <div style=" width: 100vw; height: 300px;" ref="container"></div>
    </div>
  </template>

<script>
export default {
  props: ['contributionData'],
  mounted () {
    this.initDate()
  },
  data () {
    return {
    }
  },
  created () {

  },
  methods: {
    initDate: function () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.echarts.init(this.$refs.container)
      var option

      var data = this.contributionData
      option = {
        tooltip: {
          trigger: 'item'
        },
        calendar: [{
          top: 100,
          left: 'center',
          range: '2016',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#000',
              width: 0,
              type: 'solid'
            }
          },
          yearLabel: {
            show: false
          },
          monthLabel: {
            nameMap: 'en',
            textStyle: {
              color: '#44A340'
            },
            fontWeight: 'bold'
          },
          dayLabel: {
            nameMap: 'en',
            textStyle: {
              color: '#44A340'
            },
            fontWeight: 'bold'
          },
          itemStyle: {
            normal: {
              color: '#EEEEEE',
              borderWidth: 1,
              borderColor: '#fff'
            }
          }
        }],
        series: [
          {
            name: '文章',
            type: 'scatter',
            coordinateSystem: 'calendar',
            data: data,
            symbolSize: function (val) {
              return val[1] / 5
            },
            itemStyle: {
              normal: {
                color: '#44A340'
              }
            }
          },
          {
            name: 'Top 12',
            type: 'effectScatter',
            coordinateSystem: 'calendar',
            data: data.sort(function (a, b) {
              return b[1] - a[1]
            }).slice(0, 12),
            symbolSize: function (val) {
              return val[1] / 5
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            itemStyle: {
              normal: {
                color: '#1E6823',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            zlevel: 1
          }
        ]
      }

      myChart.setOption(option)
    }
  }
}

</script>
